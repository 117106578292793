import React from "react";
import { API_URL, File } from "../../api";
import { useUser } from "../../hooks/useUser";

export default function Finalpage(props) {
  let {
    applicationsignedDocumentsEnvelopeId,
    fileNameOriginal,
    signedDocumentsEnvelopeId,
    fileNameUpdated,
    fileNameUpdated1,
    fileNameUpdated2,
    fileNameUpdated4,
    fileNameUpdated5,
    fileNameUpdated6,
    fileNameUpdated7,
    ItemId,
    fileNameOriginal1,
    fileNameOriginal2,
    fileNameOriginal4,
    fileNameOriginal5,
    fileNameOrginal6,
    fileNameOriginal7,
  } = props;
  const { roleName = "" } = useUser() || {};
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div id="files-upload" className="">
            <div className="card mb-4 border-primary shadow ">
              <div className="card-header  border-bottom">
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="mb-0">Final Release </h2>
                </div>
              </div>
              <div className="card-footer border-0">
                {roleName === "Approving Representative" || roleName === "Engineering Representative" ? (
                  <div className="mb-3">
                    <label htmlFor="license-agreement" className="form-label">
                      Application
                    </label>
                    <div className="card bg-secondary border-0">
                      <div className="card-body p-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{"Application"}</div>
                          <div className="flex-shrink-0">
                            <a href={API_URL + File + (applicationsignedDocumentsEnvelopeId ? "1_" : "") + ItemId + ".pdf"} target="_blank" className="btn btn-link">
                              View
                            </a>
                            <a href={API_URL + File + (applicationsignedDocumentsEnvelopeId ? "1_" : "") + ItemId + ".pdf"} target="_blank" className="btn btn-link" download>
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {roleName === "applicant" ||
                roleName === "Approving Representative" ||
                roleName === "Operating Representative" ||
                roleName === "Operating Signee" ||
                roleName === "Engineering Representative" ||
                roleName === "Signing Representative" ||
                roleName === "Approving Accountant" ? (
                  <div className="mb-3">
                    <label htmlFor="license-agreement" className="form-label">
                      License Agreement
                    </label>
                    <div className="card bg-secondary border-0">
                      <div className="card-body p-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal}</div>
                          <div className="flex-shrink-0">
                            <a
                              href={API_URL + File + (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 ? signedDocumentsEnvelopeId.length + "_" : "") + fileNameUpdated}
                              target="_blank"
                              className="btn btn-link"
                            >
                              View
                            </a>
                            <a
                              href={API_URL + File + (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 ? signedDocumentsEnvelopeId.length + "_" : "") + fileNameUpdated}
                              target="_blank"
                              className="btn btn-link"
                              download
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {roleName === "Approving Representative" ||
                roleName === "Operating Representative" ||
                roleName === "Operating Signee" ||
                roleName === "Engineering Representative" ||
                roleName === "Signing Representative" ||
                roleName === "Approving Accountant" ? (
                  <div className="mb-3">
                    <label htmlFor="license-agreement" className="form-label">
                      Certificates of Insurance
                    </label>
                    <div className="card bg-secondary border-0 mb-2">
                      <div className="card-body">
                        <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal1}</div>
                          <div className="flex-shrink-0">
                            <a href={API_URL + File + fileNameUpdated1} target="_blank" className="btn btn-link">
                              View
                            </a>
                            <a href={API_URL + File + fileNameUpdated1} target="_blank" download className="btn btn-link">
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card bg-secondary border-0 mb-2">
                      <div className="card-body ">
                        <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal2}</div>
                          <div className="flex-shrink-0">
                            <a href={API_URL + File + fileNameUpdated2} target="_blank" className="btn btn-link">
                              View
                            </a>
                            <a href={API_URL + File + fileNameUpdated2} target="_blank" download className="btn btn-link">
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {roleName === "Operating Representative" ||
                roleName === "Operating Signee" ||
                roleName === "Approving Representative" ||
                roleName === "Engineering Representative" ||
                roleName === "Signing Representative" ||
                roleName === "Approving Accountant" ? (
                  <div className="mb-3">
                    <label htmlFor="license-agreement" className="form-label">
                      Norfolk Southern Approval Letter Uploaded
                    </label>
                    <div className="card bg-secondary border-0">
                      <div className="card-body p-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal4}</div>
                          <div className="flex-shrink-0">
                            <a href={API_URL + File + fileNameUpdated4} target="_blank" className="btn btn-link">
                              View
                            </a>
                            <a href={API_URL + File + fileNameUpdated4} target="_blank" download className="btn btn-link">
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label htmlFor="license-agreement" className="form-label">
                      Recent Insurance Approvals
                    </label>
                    <div className="card bg-secondary border-0">
                      <div className="card-body p-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal7}</div>
                          <div className="flex-shrink-0">
                            <a href={API_URL + File + fileNameUpdated7} target="_blank" className="btn btn-link">
                              View
                            </a>
                            <a href={API_URL + File + fileNameUpdated7} target="_blank" download className="btn btn-link">
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {roleName === "Approving Representative" ||
                roleName === "Engineering Representative" ||
                roleName === "Signing Representative" ||
                roleName === "Signing Representative" ||
                roleName === "Approving Accountant" ? (
                  <div className="mb-3">
                    <label htmlFor="license-agreement" className="form-label">
                      Sealed Certification Form
                    </label>
                    <div className="card bg-secondary border-0">
                      <div className="card-body p-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal5}</div>
                          <div className="flex-shrink-0">
                            <a href={API_URL + File + fileNameUpdated5} target="_blank" className="btn btn-link">
                              View
                            </a>
                            <a href={API_URL + File + fileNameUpdated5} target="_blank" download className="btn btn-link">
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {roleName === "applicant" ||
                roleName === "Approving Representative" ||
                roleName === "Engineering Representative" ||
                roleName === "Signing Representative" ||
                roleName === "Approving Accountant" ||
                roleName === "Operating Representative" ? (
                  <div className="mb-3">
                    <label htmlFor="license-agreement" className="form-label">
                      Final Application Approval Letter (Release Letter)
                    </label>
                    <div className="card bg-secondary border-0">
                      <div className="card-body p-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{fileNameOrginal6}</div>
                          <div className="flex-shrink-0">
                            <a href={API_URL + File + fileNameUpdated6} className="btn btn-link" target="_blank">
                              View
                            </a>
                            <a href={API_URL + File + fileNameUpdated6} className="btn btn-link" target="_blank" download>
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
