// Utility function to extract consultant roles from contacts
export const extractConsultantRoles = (consultantContacts) => {
    return consultantContacts.reduce((acc, contact) => {
      const roles = contact.companyroles
        .filter((role) => role.company.companyType === "CONSULTANT")
        .map((role) => ({
          ...contact,
          companyId: role.company.id,
          companyName: role.company.name,
          contactRoleId:role.id
        }));
      return [...acc, ...roles];
    }, []);
  };