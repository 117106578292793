import { useState } from "react";
import Select from "react-select";

export const MultiSelect = ({ options, defaultValue, onChange, ...rest }) => {
  const selectAllOption = { label: "Select All", value: "*" };

  const initialVisibleOptions = options.length === defaultValue?.length ? options : [selectAllOption, ...options];

  const [availableOptions, setAvailableOptions] = useState(initialVisibleOptions);

  const [selectedValues, setSelectedValues] = useState(defaultValue);

  const relayOnChange = (newSelectedOptions) => {
    const selectAllIsSelected = !!newSelectedOptions.find((o) => o.value === selectAllOption.value);

    const newComponentState = selectAllIsSelected
      ? {
          selectedValues: options,
          availableOptions: [],
        }
      : {
          selectedValues: newSelectedOptions,
          availableOptions: initialVisibleOptions,
        };

    setSelectedValues(newComponentState.selectedValues);
    setAvailableOptions(newComponentState.availableOptions);
    onChange(newComponentState.selectedValues);
  };
  return <Select isClearable isSearchable isMulti options={availableOptions} value={selectedValues} defaultValue={selectedValues} onChange={relayOnChange} {...rest} />;
};
