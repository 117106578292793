/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { getCommentsDraftAgreement, getLicenseStatus, getcurrentStatus, getFormData, sendNotify } from "../../api/currentApplications";
import UploadDraftLoading from "../loaders/UploadDraftLoading";
import { API_URL, File } from "../../api";
import DraftCommentComments from "./DraftCommentComments";
import { netSuiteUpsertRecords } from "../../api/Netsuite";
import { useApplication } from "../../hooks/useApplication";
import { useUser } from "../../hooks/useUser";
import { socket } from "../../utils/socket-io";

export default function DraftAggrementReview(props) {
  let { ItemId } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [draftValue, setDraftValue] = useState({});
  const [loading, setLoading] = useState(true);
  const [allComments, setAllComments] = useState([]);
  const [stage, setStage] = useState("");

  useEffect(async () => {
    let response = await getLicenseStatus(ItemId);
    setDraftValue(response.data.data);
    setLoading(false);
    let newRes = await getcurrentStatus(ItemId);
    setStage(newRes.data.data);
  }, []);

  let draft = draftValue.draft || {};
  let draftdata = draft && draft[0];
  let { licenseFee, AgreementData, supportingData } = draftdata || {};
  let { action: action1, date: date1 } = draftdata || {};
  let { action, date } = draftdata || {};
  let license_value = licenseFee && licenseFee[0];
  let supporting_doc_value = (supportingData && supportingData[0]) || {};

  let { license_fee } = license_value || "";
  let { fileNameOriginal, fileNameUpdated } = (AgreementData && AgreementData[0]) || {};

  const onSubmit = async (d) => {
    props.draftAggrementReview(d);
    await netSuiteUpsertRecords(ItemId, "AGREEMENT", {
      nsactivity: d.activity_number,
      originalchargeamount: license_fee,
    });
  };

  const user = useUser() || {};
  const { roleName = "", contact = {} } = user;

  useEffect(async () => {
    socket.emit("chat send");
    socket.on("chat send", (msg) => {
      setAllComments((prev) => [msg]);
    });

    let responseComment = await getCommentsDraftAgreement(ItemId);
    responseComment.data.data.draft.map((value) => (value.action === "Upload and Send to NCRR" ? setAllComments(value.comment && value.comment) : null));
    return () => {
      setAllComments({}); // This worked for me
    };
  }, []);

  useEffect(async () => {
    getFormData(ItemId).then(({ data }) => {
      if (data && data.signedDocumentsEnvelopeId) {
        setapplicationsignedDocumentsEnvelopeId(data.signedDocumentsEnvelopeId);
      }
    });
    const object = {
      applicationID: ItemId,
      signerEmail: ["Test"],
    };
    let response = await sendNotify(object);
    setCompileValue(response.data.responseData);
  }, []);
  const [compileValue, setCompileValue] = useState({});
  const [applicationsignedDocumentsEnvelopeId, setapplicationsignedDocumentsEnvelopeId] = useState("");
  let currentEmail = contact.email;
  const SignedDate = compileValue ? (compileValue.signedAt ? new Date(compileValue.signedAt).toString().slice(0, 15) : "") : "";
  let { name: SignerName, email: SignerEmail } = compileValue || {};
  const validateSignedDate = compileValue ? (compileValue.validatedat ? new Date(compileValue.validatedat).toString().slice(0, 15) : "") : "";
  return loading ? (
    <>
      <UploadDraftLoading />
      <UploadDraftLoading />
    </>
  ) : (
    <div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div id="files-upload" className="">
              <div className="card mb-4 position-relative comment-card">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Sign Application</h2>
                    <small className="text-muted">By Applicant</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <div className="mb-2 mt-2">
                      {SignerEmail === currentEmail ? (
                        <label className="text-muted d-block form-label d-block">You are authorized to sign the application.</label>
                      ) : (
                        <label className="text-muted d-block form-label d-block">{"Application Signed"}</label>
                      )}

                      {SignerName && (
                        <>
                          <div className="alert-success alert fs-5 mt-3 mb-0 text-center">{`${SignerName} digitally Signed this Application.`}</div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i> Signed
                      </span>
                      <span>
                        By {SignerName} on <small> {SignedDate ? SignedDate : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="card mb-4 shadow ">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Validate Application Signature</h2>
                    <small className="text-muted">For HNTB</small>
                  </div>
                </div>
                <div className="card-body pt-0 pb-0">
                  <div className="card bg-secondary border-0">
                    <div className="card-body p-3">
                      <div>
                        <span className="mb-0 text-muted">Application</span>
                        <a
                          // style={role==="Approving Representative" ? {}:{ pointerEvents: "none" }}
                          target="_blank"
                          href={API_URL + File + (applicationsignedDocumentsEnvelopeId ? "1_" : "") + ItemId + ".pdf"}
                          className="btn btn-link"
                        >
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i> Validated
                      </span>
                      <span>
                        By HNTB on <small>{validateSignedDate ? validateSignedDate : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="card mb-4 position-relative comment-card">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Agreement Generated</h2>
                    <small className="text-muted">By HNTB</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <h2 className="mb-0">Agreement Generated </h2> */}
                    <span className="mb-0"></span>
                    {/* <small className="text-muted">By HNTB</small> */}
                    {roleName === "Engineering Representative" || roleName === "Approving Representative" ? (
                      <button type="button" className="btn-outline-success btn-sm btn text-scucess comment-btn position-relative" data-bs-toggle="modal" data-bs-target="#comment-modal">
                        {stage.currentStep >= 12 ? "View" : <>{allComments && allComments.length > 0 ? "Comments" : " Start New Comment"}</>}
                      </button>
                    ) : null}
                  </div>{" "}
                  <DraftCommentComments
                    license_fee={license_fee}
                    fileNameOriginal={fileNameOriginal}
                    fileNameUpdated={fileNameUpdated}
                    stage={stage.currentStep}
                    supporting_doc_value={supporting_doc_value}
                  />
                  <div>
                    <div className="mb-3">
                      <label className="text-muted d-block">Uploaded Agreement</label>
                      <div className="card bg-secondary border-0">
                        <div className="card-body p-3">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <i className="fal fa-file-pdf"></i>
                            </div>
                            <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal}</div>
                            <div className="flex-shrink-0">
                              <a href={API_URL + File + fileNameUpdated} target="_blank" className="btn btn-link">
                                View
                              </a>
                              <a href={API_URL + File + fileNameUpdated} target="_blank" className="btn btn-link">
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(roleName === "Engineering Representative" || roleName === "Approving Representative") && (
                      <>
                        <div className="mb-3">
                          <label className="text-muted d-block">Supporting Document</label>

                          {supporting_doc_value.fileDataMultiple &&
                            supporting_doc_value.fileDataMultiple.map((supporting_doc_value, ind) => (
                              <div className="card-body p-0" key={ind}>
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0">
                                    <i className="fal fa-file-pdf"></i>
                                  </div>
                                  <div className="flex-grow-1 ms-3 w-50">{supporting_doc_value.fileNameOriginal ? supporting_doc_value.fileNameOriginal : fileNameOriginal}</div>
                                  <div className="flex-shrink-0">
                                    <a href={API_URL + File + (supporting_doc_value.fileNameUpdated ? supporting_doc_value.fileNameUpdated : null)} target="_blank" className="btn btn-link">
                                      View
                                    </a>
                                    <a href={API_URL + File + (supporting_doc_value.fileNameUpdated ? supporting_doc_value.fileNameUpdated : null)} target="_blank" className="btn btn-link">
                                      Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                  </div>
                  {roleName === "Engineering Representative" || roleName === "Approving Representative" ? (
                    <div className="mb-3">
                      <label className="text-muted d-block">Entered License Fee</label>
                      <div className="card bg-secondary border-0">
                        <div className="card-body p-3">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">${license_fee}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a href="#" className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i>
                        {action}
                      </span>
                      <span>
                        {""} By HNTB on
                        <small> {date ? date.slice(0, 15) : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">NCRR Review Draft Agreement</h2>
                    <small className="text-muted">By NCRR</small>
                  </div>
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a href="#" className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i>
                        {action1}
                      </span>
                      <span>
                        {""} By NCRR on
                        <small> {date1 ? date1.slice(0, 15) : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card mb-4 border-primary shadow ">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Agreement Review</h2>
                      <small className="text-muted">For Norfolk Southern</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="mb-0">
                      <label className="text-muted d-block">Enter Activity Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="activity_number"
                        name="activity_number"
                        disabled={roleName !== "Operating Representative"}
                        {...register("activity_number", { required: true })}
                      />
                    </div>
                    {errors.activity_number && <span className="text-danger">This field is required</span>}
                  </div>
                  {roleName !== "Operating Representative" ? (
                    <div className="card-footer justify-content-center " style={{ backgroundColor: "rgb(233 237 241)" }}>
                      Awaiting for Norfolk Southern
                      <i className="fal fa-chevron-right ms-2"></i>
                    </div>
                  ) : (
                    <div className="card-footer justify-content-center bg-primary">
                      <button disabled={roleName !== "Operating Representative"} className="d-block text-white" style={{ border: "none", backgroundColor: "#5E74E4" }}>
                        {roleName !== "Operating Representative" ? "Awaiting for Norfolk Southern" : "  Send to HNTB"}
                        <i className="fal fa-chevron-right ms-2"></i>
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
