/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { addCommentsDraftAgreement, getCommentsDraftAgreement, getFormData, getLicenseStatus, sendNotify, updateAgreementFile } from "../../api/currentApplications";
import UploadDraftLoading from "../loaders/UploadDraftLoading";
import { useLocation } from "react-router-dom";
import { REACT_APP_NS_risk_finance_fee_original, File, REACT_APP_URL, API_URL } from "../../api";
import checkFileName from "../../utils/checkSpecialCharacter";
import LicenseUpdateComment from "./LicenseUpdateComment";
import { useUser } from "../../hooks/useUser";
import { socket } from "../../utils/socket-io";

export default function UploadInsurance(props) {
  const [disable, setDisable] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [allComments, setAllComments] = useState([]);
  const [compileValue, setCompileValue] = useState([]);
  const [editorState, setEditorState] = useState();
  const [applicationDetails, setApplicationDetails] = useState("");
  const [notification, setNotification] = useState({});
  const [showEditValue, setShowEditValue] = useState(true);
  const [allCommentsApplicant, setAllCommentsApplicant] = useState([]);
  const [commentsText, setCommentsText] = useState("");
  const [ID, setID] = useState();
  const [qId, setQid] = useState(0);
  const [singlehntbcommt, setSingleHntbcommt] = useState(false);
  const [singleApplicantcommt, setSingleApplicantcommt] = useState(false);
  const [file, setFile] = useState([]);
  const [compileSignedValue, setCompileSignedValue] = useState([]);
  const [riskFile, setRiskFile] = useState(false);
  let { ItemId } = props;
  const [loading, setLoading] = useState(true);
  const user = useUser() || {};
  const { roleName = "", contact = {}, id: contactRoleId } = user;

  useEffect(async () => {
    let responses = await getLicenseStatus(ItemId);
    setCompileValue(responses.data.data);
    setLoading(false);
    // if (
    //   query.get("event") === "signing_complete" &&
    //   query.get("downloaded") !== "True"
    // ) {
    //   let data = {
    //     applicationID: ItemId,
    //     signerEmail: ["Test"],
    //     userId: contactRoleId,
    //     LinkUrl: process.env.REACT_APP_URL + window.location.pathname,
    //     status: "Complete",
    //     agreementType: "CompiledAgreement",
    //   };
    //   let datas = await sendNotify(data);
    // }
    const object = {
      applicationID: ItemId,
      signerEmail: ["Test"],
      agreementType: "CompiledAgreement",
    };
    let response = await sendNotify(object);
    setCompileSignedValue(response.data.responseData);
  }, []);

  useEffect(async () => {
    socket.emit("chat send");
    socket.on("chat send", (msg) => {
      msg.action === "Uploaded" && setAllComments((prev) => (prev ? [...prev, msg] : [msg]));

      msg.action === "Sign and Paid" && setAllCommentsApplicant((prev) => (prev ? [...prev, msg] : [msg]));
    });
    let responseComment = await getCommentsDraftAgreement(ItemId);

    responseComment.data.data.compiled.map((value) => (value.action === "Uploaded" ? setAllComments(value.comment && value.comment) : null));
    responseComment.data.data.compiled.map((value) => (value.action === "Sign and Paid" ? setAllCommentsApplicant(value.comment && value.comment) : null));
    let responsee = await getLicenseStatus(ItemId);
    setCompileValue(responsee.data.data);
  }, []);

  useEffect(async () => {
    let details = await getFormData(ItemId);
    setApplicationDetails(details.data);
  }, []);

  const [characterError, setCharacterError] = useState({
    Certificates_of_Insurance_1: false,
    Certificates_of_Insurance_2: false,
    Certificates_of_Insurance_3: false,
    Certificates_of_Insurance_4: false,
    Certificates_of_Insurance_1Name: "",
    Certificates_of_Insurance_2Name: "",
    Certificates_of_Insurance_3Name: "",
    Certificates_of_Insurance_4Name: "",
  });

  const feesvaidate = (data) => {
    if (data === true) {
      localStorage.setItem("Risk_Finance", true);
      localStorage.setItem("Risk_Finance_file", false);
      setDisable(true);
    } else {
      localStorage.setItem("Risk_Finance", false);
      setDisable(false);
    }
  };

  const uploaded = compileValue.compiled && compileValue.compiled.filter((data) => data.action === "Uploaded");

  let resolvedValue = uploaded?.[0]?.resolved || false;

  // -------------edit comment----------
  const putCommentEditforAgreement = () => {
    if (commentsText.length) {
      const date = new Date();
      const formData = {
        id: user.id,
        name: user.contact.name,
        role: user.role.role || user.roleName,
        email: user.contact.email,
        roleName: user.roleName,
      };
      const data = {
        questionID: ID,
        agreementType: "compiled",
        comment: commentsText,
        timeStamp: date.toDateString(),
        from: JSON.stringify(formData),
        applicationID: ItemId,
        files: file,
        action: "Uploaded",
      };
      socket.emit("chat message", data);

      addCommentsDraftAgreement(data)
        .then(({ data }) => {
          setEditorState("");
          setCommentsText("");
          if ((!uploaded.comment || uploaded.resolved === true) && roleName === "Engineering Representative") {
            // UnresolveCompileCommentEdit();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Please add comment");
    }
  };

  const UnresolveCompileCommentEdit = async () => {
    const data = {
      applicationID: ItemId,
      action: "Sign and Paid",
      agreementType: "compiled",
      unresolveComment: true,
    };
    await addCommentsDraftAgreement(data)
      .then(({ data }) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resolveCompileCommentEdit = async () => {
    setLoading(true);
    const data = {
      applicationID: ItemId,
      action: "Uploaded",
      agreementType: "compiled",
      resolveComment: true,
    };
    await addCommentsDraftAgreement(data);
    setLoading(false);
    window.location.reload();
  };
  function handleComment(comment, id) {
    setCommentsText(comment);
    setID(id);
  }
  function handleCommentEdit(comment, id) {
    setCommentsText(comment);
    setID(id);
  }
  const sendNotification = async (commentType, notificationTo) => {
    setNotification({});
    const object = {
      applicationID: ItemId,
      signerEmail: ["Test"],
      agreementType: "CompiledAgreement",
      notificationTo: notificationTo,
      customerID: applicationDetails.createdby,
      LinkUrl: process.env.REACT_APP_URL + window.location.pathname,
    };
    await sendNotify(object);

    setNotification(notificationTo);
    setSingleHntbcommt(false);
    setSingleApplicantcommt(false);
  };

  // ------------end edit comment--------

  const editvalue = () => {
    setShowEditValue(false);
  };
  const UpdateLicense = async () => {
    const license_file = document.getElementById("License_Agreement").files[0];
    const formData = new FormData();
    formData.append("applicationID", ItemId);
    formData.append("license_file", license_file);
    try {
      await updateAgreementFile(formData);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmit = (d) => {
    if (!characterError.Certificates_of_Insurance_1 && !characterError.Certificates_of_Insurance_2 && !characterError.Certificates_of_Insurance_3 && !characterError.Certificates_of_Insurance_4)
      props.uploadInsurancedata(d);
  };
  const riskfilevaidate = (data) => {
    if (data === true) {
      localStorage.setItem("Risk_Finance_file", true);
      localStorage.setItem("Risk_Finance", false);
      setRiskFile(true);
    } else {
      localStorage.setItem("Risk_Finance_file", false);
      setRiskFile(false);
    }
  };

  let { compiled } = compileValue || [];
  let compiles = (compiled && compiled[0]) || [];
  let { action, date, formData } = compiles || {};

  let filedata = formData && formData[0];
  let { fileNameOriginal, fileNameUpdated, signedDocumentsEnvelopeId } = filedata || "";

  let filedata1 = formData && formData[1];
  let paymentInstructionsfileName = (filedata1 && filedata1.fileNameOriginal) || "";
  let paymentInstructionsfileNameUpdated = (filedata1 && filedata1.fileNameUpdated) || "";

  const query = new URLSearchParams(useLocation().search);
  const commentResult = [];

  allComments &&
    allComments.map((val) => {
      let roles = JSON.parse(val.from);
      return commentResult.push(roles.role);
    });

  let EngineeringComment = commentResult && commentResult.filter((d) => d === "Engineering Representative");
  return loading ? (
    <>
      <UploadDraftLoading />
      <UploadDraftLoading />
    </>
  ) : (
    <div>
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-fullscreen ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Sample NCRR Insurance
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <embed
                src={`${process.env.REACT_APP_API_URL}/static/COI GL NCRR.pdf`}
                // {"https://www.clickdimensions.com/links/TestPDFfile.pdf" }
                frameBorder="0"
                width="100%"
                height="100%"
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-fullscreen ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel1">
                Sample NSR Insurance
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <embed
                src={`${process.env.REACT_APP_API_URL}/static/COI GL NSR.pdf`}
                // {"https://www.clickdimensions.com/links/TestPDFfile.pdf" }
                frameBorder="0"
                width="100%"
                height="100%"
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div id="files-upload" className="">
              <div className="inner-form card mb-4">
                <div className="card-header border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Compiled License Agreement</h2>
                    <small className="text-muted">By HNTB</small>
                  </div>
                </div>
                <div className="card-body pb-0">
                  {roleName === "applicant" && allComments && allComments.length > 0 && resolvedValue === false && (
                    <>
                      {notification == "engineeringRepresentatives" ? (
                        <div className="position-absolute notifyBtn">
                          <a className="btn btn-success m-0 disabled">
                            <small>
                              <i className="fas fa-check-double"></i> Notification Sent
                            </small>
                          </a>
                        </div>
                      ) : (
                        <>
                          {singlehntbcommt === true ? (
                            <>
                              <div className="position-absolute notifyBtn">
                                <a className="btn btn-outline-success m-0 disabled">
                                  <small>
                                    <i className="far fa-check"></i> Notification Sent
                                  </small>
                                </a>
                              </div>
                            </>
                          ) : (
                            <div className="position-absolute notifyBtn">
                              <a onClick={() => sendNotification("applicant", "engineeringRepresentatives", setSingleHntbcommt(true))} className="btn btn-primary text-white m-0">
                                <small>Notify HNTB</small>
                              </a>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {roleName == "Engineering Representative" && EngineeringComment && EngineeringComment.length > 0 && resolvedValue === false && (
                    <>
                      {notification == "applicant" ? (
                        <div className="position-absolute notifyBtn">
                          <a className="btn btn-success m-0 disabled">
                            <small>
                              <i className="fas fa-check-double"></i> Notification Sent
                            </small>
                          </a>
                        </div>
                      ) : (
                        <>
                          {singleApplicantcommt === true ? (
                            <div className="position-absolute notifyBtn">
                              <a className="btn btn-outline-success m-0 disabled">
                                <small>
                                  <i className="far fa-check"></i> Notification Sent
                                </small>
                              </a>
                            </div>
                          ) : (
                            <div className="position-absolute notifyBtn">
                              <a onClick={() => sendNotification("engineeringRepresentatives", "applicant", setSingleApplicantcommt(true))} className="btn btn-primary text-white m-0">
                                <small>Notify Applicant</small>
                              </a>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <label htmlFor="license-agreement" className="form-label">
                      Uploaded License Agreement
                    </label>
                    <LicenseUpdateComment
                      ID={ID}
                      qId={qId}
                      applicationID={ItemId}
                      fileNameOriginal={fileNameOriginal}
                      signedDocumentsEnvelopeId={signedDocumentsEnvelopeId}
                      fileNameUpdated={fileNameUpdated}
                      handleCommentEdit={handleCommentEdit}
                      setFile={setFile}
                      putCommentEdit={putCommentEditforAgreement}
                      resolvedValues={resolvedValue}
                      resolveCompileComment={resolveCompileCommentEdit}
                      UnresolveCompileCommentEdit={UnresolveCompileCommentEdit}
                      handleComment={handleComment}
                      stage={13}
                    />
                    {(showEditValue && resolvedValue === false && roleName === "Engineering Representative" && allComments && allComments.length > 0) ||
                    (resolvedValue === false && roleName === "applicant") ? (
                      <button
                        className="btn-outline-dark btn-sm 
                      btn text-dark comment-btn
                       position-relative"
                        data-bs-toggle="modal"
                        data-bs-target="#comment-modaledit"
                      >
                        {(allComments && allComments.length === 0) || allComments === undefined ? "Start New Comment" : "Comments"}
                      </button>
                    ) : null}
                    {resolvedValue === true && (
                      <button
                        className="btn-outline-success btn-sm 
                      btn text-success comment-btn
                       position-relative"
                        data-bs-toggle="modal"
                        data-bs-target="#comment-modaledit"
                      >
                        <i className="far fa-check text-success"></i> Resolved
                      </button>
                    )}
                  </div>
                  <div className="card bg-secondary border-0">
                    <div className="card-body p-3">
                      {showEditValue && (
                        <>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <i className="fal fa-file-pdf"></i>
                            </div>
                            <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal}</div>
                            <div className="flex-shrink-0">
                              <a
                                href={API_URL + File + (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 ? signedDocumentsEnvelopeId.length - 1 + "_" : "") + fileNameUpdated}
                                target="_blank"
                                className="btn btn-link"
                              >
                                View
                              </a>
                              <a
                                href={API_URL + File + (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 ? signedDocumentsEnvelopeId.length - 1 + "_" : "") + fileNameUpdated}
                                target="_blank"
                                className="btn btn-link"
                              >
                                Download
                              </a>
                            </div>
                          </div>
                          {resolvedValue === false && roleName === "Engineering Representative" && allComments && allComments.length > 0 && (
                            <button
                              className="btn btn-link pb-0"
                              style={{
                                textDecoration: "underline",
                                pointerEvents: "fill",
                              }}
                              onClick={() => {
                                editvalue();
                              }}
                            >
                              <i className="far fa-pen"></i> Edit
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {!showEditValue && (
                    <>
                      <div className="mb-4">
                        <input
                          type="file"
                          className="form-control"
                          id="License_Agreement"
                          accept=".pdf"
                          disabled={roleName !== "Engineering Representative"}
                          {...register("License_Agreement", {
                            required: true,
                            onChange: (e) => {
                              const fileName = e.target.files[0].name;
                              if (!checkFileName(fileName)) {
                                setCharacterError((data) => ({
                                  ...data,
                                  License_Agreement: true,
                                  License_AgreementName: fileName,
                                }));
                              } else {
                                setCharacterError((data) => ({
                                  ...data,
                                  License_Agreement: false,
                                  License_AgreementName: "",
                                }));
                              }
                            },
                          })}
                        />
                        <span className="text-danger">
                          <span>
                            {errors.License_Agreement && errors.License_Agreement.type === "required" && <span>This field is required</span>}
                            {characterError.License_Agreement && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                          </span>
                        </span>
                      </div>
                      <button
                        className="btn btn-link"
                        style={{
                          textDecoration: "underline",
                          pointerEvents: "fill",
                        }}
                        onClick={() => {
                          UpdateLicense();
                        }}
                      >
                        <i className="far fa-check"></i> Save
                      </button>
                      <a
                        className="btn btn-link text-warning"
                        style={{
                          textDecoration: "underline",
                          pointerEvents: "fill",
                        }}
                        href={REACT_APP_URL + "/compiledAgreement/" + ItemId}
                      >
                        <i className="far fa-ban"></i> Cancel
                      </a>
                    </>
                  )}
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i> {action}{" "}
                      </span>
                      <span>
                        By HNTB Representative on <small> {date ? date.slice(0, 15) : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="card mb-4 border-primary shadow ">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Upload Insurance</h2>
                    <small className="text-muted">For Applicant</small>
                  </div>
                </div>

                <div className="card-body">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label className="text-muted d-block form-label">Payment Instructions</label>
                    </div>
                    <div className="card bg-secondary border-0">
                      <div className="card-body p-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{paymentInstructionsfileName}</div>
                          <div className="flex-shrink-0">
                            <a href={API_URL + File + paymentInstructionsfileNameUpdated} target="_blank" className="btn btn-link">
                              View
                            </a>
                            <a href={API_URL + File + paymentInstructionsfileNameUpdated} target="_blank" className="btn btn-link">
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="text-muted d-block form-label">General and Automobile Liability Certificates of Insurance (both required)</label>
                    <p className="mb-3">
                      <small>
                        Upload the Licensee's certificate of insurance evidencing general liability and automobile liability insurance policies as specified in the body of the agreement and Exhibit B,
                        Article 9. On separate certificates, name the following as additional insured and certificate holder:
                      </small>
                    </p>
                    <div className="card bg-secondary border-0 mb-3">
                      <div className="ms-auto text-end">
                        {" "}
                        <a
                          // href="https://www.clickdimensions.com/links/TestPDFfile.pdf"
                          className="form-label"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          style={{
                            color: "#0079C2",
                            fontWeight: "400",
                            fontSize: "14px",
                            textAlign: "right",
                          }}
                        >
                          Sample NCRR Insurance
                        </a>
                      </div>
                      <div className="card-body">
                        <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                        <div className="input-group">
                          <input
                            accept=".pdf"
                            type="file"
                            className="form-control"
                            name="Certificates_of_Insurance_1"
                            id="Certificates_of_Insurance_1"
                            {...register("Certificates_of_Insurance_1", {
                              required: true,
                              onChange: (e) => {
                                const fileName = e.target.files[0].name;
                                if (!checkFileName(fileName)) {
                                  setCharacterError((data) => ({
                                    ...data,
                                    Certificates_of_Insurance_1: true,
                                    Certificates_of_Insurance_1Name: fileName,
                                  }));
                                } else {
                                  setCharacterError((data) => ({
                                    ...data,
                                    Certificates_of_Insurance_1: false,
                                    Certificates_of_Insurance_1Name: "",
                                  }));
                                }
                              },
                            })}
                            disabled={roleName !== "applicant"}
                          />
                          <label className="input-group-text bg-secondary" htmlFor="Certificates_of_Insurance_1">
                            Upload
                          </label>
                        </div>
                        <span className="text-danger">
                          <span>
                            {errors.Certificates_of_Insurance_1 && errors.Certificates_of_Insurance_1.type === "required" && <span>This field is required</span>}
                            {characterError.Certificates_of_Insurance_1 && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="card bg-secondary border-0 mb-5">
                      <div className="ms-auto text-end">
                        {" "}
                        <a
                          // href="https://www.clickdimensions.com/links/TestPDFfile.pdf"
                          className="form-label"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal1"
                          style={{
                            color: "#0079C2",
                            fontWeight: "400",
                            fontSize: "14px",
                            textAlign: "right",
                          }}
                        >
                          Sample NSR Insurance
                        </a>
                      </div>
                      <div className="card-body">
                        <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>
                        <div className="input-group mb-3">
                          <input
                            accept=".pdf"
                            type="file"
                            className="form-control"
                            multiple
                            name="Certificates_of_Insurance_2"
                            id="Certificates_of_Insurance_2"
                            {...register("Certificates_of_Insurance_2", {
                              required: true,
                              onChange: (e) => {
                                const fileName = e.target.files[0].name;
                                if (!checkFileName(fileName)) {
                                  setCharacterError((data) => ({
                                    ...data,
                                    Certificates_of_Insurance_2: true,
                                    Certificates_of_Insurance_2Name: fileName,
                                  }));
                                } else {
                                  setCharacterError((data) => ({
                                    ...data,
                                    Certificates_of_Insurance_2: false,
                                    Certificates_of_Insurance_2Name: "",
                                  }));
                                }
                              },
                            })}
                            disabled={roleName !== "applicant"}
                          />
                          <label className="input-group-text bg-secondary" htmlFor="Certificates_of_Insurance_2">
                            Upload
                          </label>
                        </div>
                        <span className="text-danger">
                          <span>
                            {errors.Certificates_of_Insurance_2 && errors.Certificates_of_Insurance_2.type === "required" && <span>This field is required</span>}
                            {characterError.Certificates_of_Insurance_2 && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                          </span>
                        </span>
                      </div>
                    </div>
                    <hr className="bg-black" style={{ backgroundColor: " rgba(0, 0, 0, 0.5)" }} />
                    <label className="form-check-label text-muted d-block form-label mb-3">Railroad Protective Liability Certificates of Insurance (select one)</label>

                    <div>
                      <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard1">
                        <div>
                          <div className="form-check mb-3 d-flex align-items-center">
                            <input className="form-check-input" type="checkbox" value="optionOne" id="optionOne" onChange={(e) => riskfilevaidate(e.target.checked)} disabled={disable} />
                            <label className="form-check-label text-muted" htmlFor="optionOne" style={{ fontFamily: "Oswald,sans-serif" }}>
                              Option 1
                            </label>
                          </div>
                          <div id="optn1">
                            <p>
                              <small>Upload Proof of Railroad Protective Liability Insurance for NCRR and NSR as specified in the body of the agreement and Exhibit B, Article 9.</small>
                            </p>
                            <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                            <div className="input-group mb-3">
                              {riskFile === true ? (
                                <input
                                  type="file"
                                  accept=".pdf"
                                  className="form-control"
                                  name="Certificates_of_Insurance_3"
                                  id="Certificates_of_Insurance_3"
                                  {...register("Certificates_of_Insurance_3", {
                                    required: true,
                                    onChange: (e) => {
                                      const fileName = e.target.files[0].name;
                                      if (!checkFileName(fileName)) {
                                        setCharacterError((data) => ({
                                          ...data,
                                          Certificates_of_Insurance_3: true,
                                          Certificates_of_Insurance_3Name: fileName,
                                        }));
                                      } else {
                                        setCharacterError((data) => ({
                                          ...data,
                                          Certificates_of_Insurance_3: false,
                                          Certificates_of_Insurance_3Name: "",
                                        }));
                                      }
                                    },
                                  })}
                                  disabled={roleName !== "applicant"}
                                />
                              ) : (
                                <input type="file" accept=".pdf" className="form-control" name="Certificates_of_Insurance_3" id="Certificates_of_Insurance_3" disabled={roleName !== "applicant"} />
                              )}

                              <label className="input-group-text bg-secondary" htmlFor="Certificates_of_Insurance_3">
                                Upload
                              </label>
                            </div>
                            {riskFile === true ? (
                              <span className="text-danger">
                                <span>
                                  {errors.Certificates_of_Insurance_3 && errors.Certificates_of_Insurance_3.type === "required" && <span>This field is required</span>}
                                  {characterError.Certificates_of_Insurance_3 && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                                </span>
                              </span>
                            ) : (
                              <></>
                            )}
                            <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>
                            <div className="input-group mb-3">
                              {riskFile === true ? (
                                <input
                                  type="file"
                                  accept=".pdf"
                                  className="form-control"
                                  name="Certificates_of_Insurance_4"
                                  id="Certificates_of_Insurance_4"
                                  {...register("Certificates_of_Insurance_4", {
                                    required: true,
                                    onChange: (e) => {
                                      const fileName = e.target.files[0].name;
                                      if (!checkFileName(fileName)) {
                                        setCharacterError((data) => ({
                                          ...data,
                                          Certificates_of_Insurance_4: true,
                                          Certificates_of_Insurance_4Name: fileName,
                                        }));
                                      } else {
                                        setCharacterError((data) => ({
                                          ...data,
                                          Certificates_of_Insurance_4: false,
                                          Certificates_of_Insurance_4Name: "",
                                        }));
                                      }
                                    },
                                  })}
                                  disabled={roleName !== "applicant"}
                                />
                              ) : (
                                <input type="file" className="form-control" accept=".pdf" name="Certificates_of_Insurance_4" id="Certificates_of_Insurance_4" disabled={roleName !== "applicant"} />
                              )}
                              <label className="input-group-text bg-secondary" htmlFor="Certificates_of_Insurance_4">
                                Upload
                              </label>
                            </div>
                            {riskFile === true ? (
                              <span className="text-danger">
                                <span>
                                  {errors.Certificates_of_Insurance_4 && errors.Certificates_of_Insurance_4.type === "required" && <span>This field is required</span>}
                                  {characterError.Certificates_of_Insurance_4 && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                                </span>
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard2">
                        <div>
                          <div className="form-check mb-3 d-flex align-items-center">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexSwitchCheckChecked"
                              onChange={(e) => feesvaidate(e.target.checked)}
                              //   setRiskFee(e.target.value)
                              // }}
                              disabled={riskFile}
                            />
                            <label className="form-check-label text-muted" htmlFor="flexSwitchCheckChecked" style={{ fontFamily: "Oswald,sans-serif" }}>
                              Option 2
                            </label>
                          </div>
                          <div id="option2">
                            <div>
                              <p id="optn2">
                                <small>
                                  Pay ${REACT_APP_NS_risk_finance_fee_original} Risk Financing Fee to be added to NSR's Master Railroad Protective Liability Insurance coverage, as described in the
                                  attached special provision.
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {roleName !== "applicant" ? (
                  <div className="card-footer justify-content-center" style={{ backgroundColor: "rgb(233 237 241)" }}>
                    Awaiting for Applicant
                    <i className="fal fa-chevron-right ms-2"></i>
                  </div>
                ) : (
                  <div className="card-footer justify-content-center" style={{ border: "none", backgroundColor: "#5E74E4" }}>
                    <button
                      className="d-block text-white"
                      style={{
                        border: "none",
                        backgroundColor: "inherit",
                        color: "inherit",
                      }}
                    >
                      {roleName !== "applicant" ? "Awaiting for Applicant" : "Upload Insurance"} <i className="fal fa-chevron-right ms-2"></i>
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
